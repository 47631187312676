
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import { Form as Validation } from 'vee-validate'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    PageAuth,
    Validation,
    TmButton,
    TmFormLine,
    TmAlert,
  },
  setup() {
    const forgotPassword = ref({
      email: '',
    })

    const formSubmitted = ref(false)
    const onSubmit = () => {
      formSubmitted.value = true
    }

    return {
      forgotPassword,
      formSubmitted,
      onSubmit,
    }
  },
})
